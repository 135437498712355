/* color  */
.color-white {
  color: white;
}
.txt-justify{
  text-align: justify;
}
.county{
  font-size: 52px;
}
.naver{
  display: flex;
  justify-content:space-evenly;
}
.liner{
  border-left: 2px solid gray;
  height: 55px;
}

.bread {
  display: flex;
  height: auto;
  padding: 20px;
}

.ready{
  display: flex;
  height: auto;
  padding: 20px;
}
.ready-left, .ready-right{
  display:flex;
  flex:1;
}
.ready-left {
  flex-direction: column;
  padding-top: 20px;
}
.bread-left{
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  /* justify-content: center;
  text-align: center;
  justify-items: center; */
}

.bread-left h1, .bread-left span{
  font-size: 60px;
  font-weight: 900px;
  margin-top: 5vh;
  text-align: start;
  margin: 0;
}
.bread-left h1 span{
  color:#3331cf;
}
.bread-left img{
  height: 50px;
  width: 250px;
  margin-left: 20px;
  margin-top: 10px;
}

.bread-right{
  display: flex;
  flex: 2;
  justify-content: center;
  text-align: center;
}

.bread-right img{
  height: 100%;
}


/* layers  */
.layer{
  display: flex;
  padding-top: 30px;
  background-color: #212121;
}
.counter{ 
  background-color: #aeaded; 
}
/* footer section  */

footer{
  background-color: #3431cf;
  color: white;
 padding-top: 10px;
}

.footer-link{
  display: flex;
  flex-direction: row;
}

.icon-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.icon-list li {
  margin-right: 20px;
  font-size: 14px;
  padding-top: 5px;
}

/* partners */
.partners{
  background-color: 212121;
}
.partners .image-container {
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
}

.partners .image {
  width: 80px;
  height: 40px;
}


/* team  */
.author-card {
  position: relative;
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
}

.author-image {
  width: 100%;
  height: 100%;
  display: block;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.author-main{
  width: 400px;
  display: block;
}
.author-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background-color: rgba(51, 48, 207, 0.5);
  color: #fff;
  padding: 10px;
  text-align: left;
}

.author-name {
  font-size: 16px;
  font-weight: bold;
}

.author-role {
  font-size: 14px;
}

.author-card {
  position: relative;
  width: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.author-image {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.author-details {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  border-bottom-right-radius: 8px;
  text-align: left;
}

.author-name {
  font-size: 16px;
  margin-bottom: 5px;
}

.author-role {
  font-size: 14px;
}


/* support module  */
.support{
  padding: 0px;
}
.support img{
  height: 40px;
  width: 40px;
}

.cardy{
  background-color: #d9d9d9;
  height: 300px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.cardy img{
  height: 80px;
  width: 80px;
  margin: 10px;
}

.products{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Privacy */
.privacy{

  display: flex;
  flex-direction: column;
}
.privacy h1{
  font-size: 60px;
  font-weight: 900px;
  margin-top: 5vh;
  text-align: start;
  margin: 0;
}
.privacy h1 span{
  color:#3331cf;
}


/* mobile  */
/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
	.bread, .ready {
		flex-direction: column;
		align-items: center;
		height: auto;
	}

	.bread-left h1,
	.bread-left span {
		font-size: 20px;
		margin-top: 10vh;
	}

	.bread-left img {
		display: none;
	}

	.bread-left {
		margin-bottom: 20px;
	}

	.bread-right img {
		height: auto;
		width: 80%;
		/* Adjust the width as needed */
	}

  .nuller{
    display: none;
  }
}